.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  color: whitesmoke;
  background-color: #444347;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.footer{
  background-color: #262620;
}

.social-media-link{
  border-radius: 55%;
  background-color: white;
  margin: 10px;
}


.about-me{
  margin-top: 25px;
}

.profile-pic{
  margin-top: 25px;
  height: 40vh;
  width:auto;
}

.navbar-font{
  font-family: 'Chakra Petch', sans-serif;
  color: white;
}

.name{
  font-family: 'Chakra Petch', sans-serif;
}

.work{
  font-family: 'Chakra Petch', sans-serif;
}

.about-me-summary{
  font-family: 'Roboto Mono', monospace;
  margin: 0px;
  padding-bottom: 25px;
  display: inline-block;
}

.copyright{
  font-size: 1rem;
  color: white;
}

.migrately{
  height: 9vh
}

.exp{
  font-family: 'Chakra Petch', sans-serif;
  margin: 0;
}

.languages{
  font-family: 'Chakra Petch', sans-serif;
  font-size: large;
  font-weight: bolder;
  color: whitesmoke;;
}

.cv{
  font-family: 'Chakra Petch', sans-serif;
  gap: 1.2rem;
  justify-content: center;
}

.btn{
  width: max-content;
  display: inline-block;
  padding: 0.75rem 1.2rem;
  border-radius: 0.4rem;
  cursor: pointer;
  border: 1px solid;
  color: whitesmoke
}

:root{
  --container-width-lg: 75%;
  --container-width-md: 86%;
  --container-width-sm: 90%;
}

/* ====MEDIA QUERIES (MEDIUM DEVICES)==== */
@media screen and (max-width: 1024px) {
  .container{
    width: var(--container-width-md);
  }
}

/* ====MEDIA QUERIES (SMALL DEVICES)==== */
@media screen and (max-width: 600px) {
  .container{
    width: var(--container-width-sm);
  }
}
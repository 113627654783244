.title{
    font-family: 'Chakra Petch', sans-serif;
}

.subtitle{
    font-family: 'Roboto Mono', monospace;
}

.container.contact_container {
    width: 58%;
    display: grid;
    grid-template-columns: 30% 58%;
    gap: 12%
}

.contact_option{
    display: flex;
    flex-direction:column;
    gap: 1.2rem;
}

form{
    display: flex;
    flex-direction: column;
    gap: 1.2rem;
}

input, textarea{
    width: 100%;
    padding: 1.5rem;
    border-radius: 0.5rem;
    background: transparent;
    border: 2px solid;
    resize: none;
}

/* ====MEDIA QUERIES (MEDIUM DEVICES)==== */
@media screen and (max-width: 1024px) {
    .container.contact_container{
      grid-template-columns: 1fr;
      gap: 2rem;
    }
  }
  
  /* ====MEDIA QUERIES (SMALL DEVICES)==== */
  @media screen and (max-width: 600px) {
    .container.contact_container{
        width: var(--container-width-sm)
    }
  }
.book-covers{
    width: 15vw;
    height: auto;
    transition: transform .2s;
    box-shadow: rgb(38, 57, 77) 0px 20px 30px -10px;
}
.book-covers:hover {
    transform: scale(1.05); /* (150% zoom - Note: if the zoom is too large, it will go outside of the viewport) */
  }

.book-titles{
    color: red;
    margin-top: 50px;
    font-family: 'Chakra Petch', sans-serif;
}

.book-mos{
    font-weight: bold;
    font-family: 'Roboto Mono', monospace;
}

.title{
    color: red;
    font-weight: bolder;
    font-family: 'Chakra Petch', sans-serif;
    margin-top: 50px;
}

.amazon-link{
    width: 90%;
    height: auto;
    margin: 5px;
}

.about{
    font-size: 16px;
    font-weight: bold;
    line-height: 1.8;
    font-family: 'Roboto Mono', monospace;
    margin: 20px auto 20px;
    max-width: 700px;
}

/* ====MEDIA QUERIES (MEDIUM DEVICES)==== */
@media screen and (max-width: 1024px) {
    .container.contact_container{
      grid-template-columns: 1fr;
      gap: 2rem;
    }
  }
  
  /* ====MEDIA QUERIES (SMALL DEVICES)==== */
  @media screen and (max-width: 600px) {
    .container.contact_container{
        width: var(--container-width-sm)
    }
  }

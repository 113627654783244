.milsim-west-title{
    font-family: 'Chakra Petch', sans-serif;
    font-size: 10vh;
    font-weight: bolder;
    color: white
}

.milsim-west-roles{
    font-family: 'Chakra Petch', sans-serif;
    font-size: 2rem;
    font-weight: bolder;
    color: white
}

.milsim-west-about{
    font-family: 'Roboto Mono', monospace;
    font-weight: bolder;
    color: white;
    font-size: 20px;
}

.background{
    background-image: url(../../images/msw4.JPG);
    background-repeat: "no-repeat";
    background-size: "cover";
    background-position: 25% 20%;
    padding-bottom: 100px;
}
